





















































































import { Expertise } from "@/models/entities/expertise.interface";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component
export default class UserExpertiseCard extends Vue {
  @PropSync("expertiseIds", {
    required: false,
    default: function () {
      return [];
    },
    type: Array,
  })
  private expertiseIds$: number[];
  @Prop({ required: true, default: null, type: Array })
  private expertise: Expertise[];
  @Prop({ required: false, default: null, type: Number })
  private disableCheckbox: number;
  @Prop({ required: true, type: Boolean })
  private onlySelected: boolean;
  @Prop({ required: false, default: "", type: String })
  private search: string;

  private page = 1;
  private tableHeader = [
    { text: "Compagnia", value: "expertiseName" },
    { text: "Codice", value: "expertiseGesivId" },
    { text: "", value: "action" },
  ];

  get areAllChecked(): boolean {
    if (!this.expertise.length) return false;
    return this.expertise.length === this.expertiseIds$.length;
  }
  set areAllChecked(value: boolean) {
    this.expertiseIds$ = value
      ? this.expertise.map((el) => el.expertiseId)
      : [];
  }

  get tableItems(): Expertise[] {
    let ex = Object.assign([], this.expertise) as Expertise[];
    if (this.onlySelected) {
      ex = ex.filter((e) => this.expertiseIds$.indexOf(e.expertiseId) > -1);
    }
    return ex;
  }

  footerText(pagination: Record<string, unknown>): string {
    return `${pagination.pageStart}-${pagination.pageStop} di ${pagination.itemsLength}`;
  }

  prev(page: number): void {
    if (page > 1) {
      this.page--;
    }
  }

  next(page: number, pageCount: number): void {
    if (page < pageCount) {
      this.page++;
    }
  }
}
