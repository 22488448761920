var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeader,"items":_vm.tableItems,"search":_vm.search,"page":_vm.page,"fixed-header":"","disable-sort":"","hide-default-footer":"","footer-props":{ itemsPerPageOptions: [100] },"no-data-text":"Nessun dato disponibile","no-results-text":"Nessuna filiale trovata","height":"350","item-key":"expertiseId"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(!_vm.onlySelected)?{key:"header.action",fn:function(){return [_c('v-checkbox',{staticClass:"ma-0 pt-0",attrs:{"dense":"","hide-details":"","color":"var(--v-secondary-darken1)","disabled":_vm.disableCheckbox !== null},model:{value:(_vm.areAllChecked),callback:function ($$v) {_vm.areAllChecked=$$v},expression:"areAllChecked"}})]},proxy:true}:null,{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.expertiseName))]),_c('td',[_vm._v(_vm._s(item.expertiseGesivId))]),_c('td',[_c('v-checkbox',{key:item.expertiseId,staticClass:"ma-0 pt-0",attrs:{"value":item.expertiseId,"dense":"","hide-details":"","color":"var(--v-secondary-darken1)","readonly":_vm.disableCheckbox === item.expertiseId,"disabled":_vm.disableCheckbox !== item.expertiseId &&
                _vm.disableCheckbox !== null},model:{value:(_vm.expertiseIds$),callback:function ($$v) {_vm.expertiseIds$=$$v},expression:"expertiseIds$"}})],1)])]}},{key:"footer",fn:function(footer){return [_c('v-row',{staticClass:"users-card_footer",attrs:{"align":"center","justify":"end"}},[_c('v-col',{staticClass:"c-label-regular",attrs:{"cols":"auto"},domProps:{"textContent":_vm._s(_vm.footerText(footer.props.pagination))}}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"disabled":footer.props.pagination.page < 2},domProps:{"textContent":_vm._s('mdi-chevron-left')},on:{"click":function($event){return _vm.prev(footer.props.pagination.page)}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"disabled":footer.props.pagination.page >=
                footer.props.pagination.pageCount},domProps:{"textContent":_vm._s('mdi-chevron-right')},on:{"click":function($event){return _vm.next(
                  footer.props.pagination.page,
                  footer.props.pagination.pageCount
                )}}})],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }